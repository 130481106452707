<!-- 修改密码 -->
<template>
  <div class="change-pass-page">
    <!-- <TopWrapper
      :topObj="{
        bgColor: '#fff',
        title: '修改手机号',
        txtColor: '#333',
        isBack: true,
        isMsg: false,
      }"
    /> -->
    <div class="form-wrapper">
      <van-form v-show="active == 0" @failed="onFailed" ref="stepFormOne">
        <van-field
          v-model="phoneNumber"
          required
          disabled
          placeholder="请输入手机号"
          :show-error-message="true"
          :rules="[{ pattern, message: '请输入11位手机号' }]"
          name="phoneNumber"
        />
        <van-field
          v-model="smsCode"
          required
          placeholder="请输入验证码"
          :show-error-message="true"
          maxlength="6"
          name="smsCode"
          :rules="[{ required: true, message: '' }]"
        >
          <template #button>
            <van-button size="small" type="primary" @click.stop="send"
              >发送验证码</van-button
            >
          </template>
        </van-field>

        <div class="btn-box">
          <div class="btn">
            <van-button round block type="info" @click="next"
              >下一步</van-button
            >
          </div>
        </div>
      </van-form>
      <van-form v-show="active == 1" @failed="onFailed" ref="stepFormTwo">
        <van-field
          v-model="newPhone"
          required
          placeholder="输入新手机号"
          :show-error-message="true"
          :rules="[{ pattern, message: '请输入11位手机号' }]"
          name="newPhone"
        />
        <van-field
          v-model="smsCode1"
          required
          placeholder="请输入验证码"
          :show-error-message="true"
          maxlength="6"
          name="smsCode1"
          :rules="[{ required: true, message: '' }]"
        >
          <template #button>
            <van-button size="small" type="primary" @click.stop="send"
              >发送验证码</van-button
            >
          </template>
        </van-field>
        <div class="btn-box">
          <div class="btn">
            <van-button round block type="info" @click="onSubmit"
              >提交</van-button
            >
          </div>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import { Toast } from "vant";
import { getMyStatffInfo } from "@/api/setting/index.js";
import {
  getPhoneCode,
  revisePhone,
  validRawPhone,
} from "@/api/setting/index.js";
import TopWrapper from "@/components/topWrapper/index.vue";
export default {
  data() {
    return {
      active: 0,
      phoneNumber: "",
      newPhone: "",
      smsCode: "",
      smsCode1: "",
      pattern: /^1[3-9]\d{9}$/,
    };
  },

  components: { TopWrapper },

  computed: {},

  mounted() {
    this.getUserInfo();
  },

  methods: {
    getUserInfo() {
      getMyStatffInfo().then((res) => {
        if (res) {
          let { data, code } = res;
          if (code == 200) {
            this.phoneNumber = data.phoneNumber;
          }
        }
      });
    },
    onFailed(e) {
      console.log(e);
    },
    send() {
      const phone = this.active == 0 ? this.phoneNumber : this.newPhone;
      getPhoneCode({ phone: phone }).then((res) => {
        if (res) {
          let { code, msg } = res;
          if (code == 200) {
            Toast(msg);
          }
        }
      });
    },

    next(values) {
      this.$refs.stepFormOne
        .validate(["phoneNumber", "smsCode"])
        .then((res) => {
          validRawPhone({
            phone: this.phoneNumber,
            smsCode: this.smsCode,
          }).then((res) => {
            if (res) {
              let { code, msg } = res;
              if (code == 200) {
                Toast(msg);
                this.active = 1;
              }
            }
          });
        });
    },
    onSubmit() {
      this.$refs.stepFormTwo.validate(["newPhone", "smsCode1"]).then((res) => {
        const params = {
          phoneNumber: this.phoneNumber,
          newPhone: this.newPhone,
          smsCode: this.smsCode1,
        };
        revisePhone(params).then((res) => {
          if (res) {
            let { code, msg } = res;
            if (code == 200) {
              Toast(msg);
              localStorage.removeItem("Authorization_OA_H5");
              localStorage.removeItem("username_H5");
              localStorage.removeItem("staffCode_H5");
              localStorage.removeItem("openId_H5");
              this.$router.replace("/login");
            }
          }
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.change-pass-page {
  width: 100%;
  height: 100%;
  background: #fafafa;
  position: relative;
  padding-bottom: 0.2rem;
  .form-wrapper {
    padding: 0.2rem;
    .remark {
      width: 100%;
      font-size: 0.16rem;
      font-weight: 400;
      color: #999999;
      line-height: 0.2rem;
      background: #fff;
      padding: 0.2rem;
    }
    .btn-box {
      width: 90%;
      margin:1rem 0.16rem 0;
      .btn {
        width: 100%;
      }
    }
  }
}
</style>
